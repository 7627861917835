<template>
  <div>
    <CModal
      :show.sync="openModal"
      :no-close-on-backdrop="true"
      :centered="false"
      title="Modal title 2"
      size="lg"
      color="dark"
    >
      <div>
        <span
          ><Strong
            >“Si el correo incluye variables personalizables, es recomendable
            enviar primero una prueba a un grupo de correos de pruebas.”</Strong
          ></span
        >
        <br />
        <CForm @submit.prevent="envioCorreoPrueba($event)">
          <p>Boletin: {{ boletin_prueba.nombre }}</p>
          <hr />
          <CInput
            label="Correo de Prueba"
            placeholder="Ingresa Correo de Prueba"
            autocomplete="off"
            minlength="3"
            required
            v-model="email_prueba"
          />
          <CButton size="sm" color="danger" type="submit">
            Enviar Correo de Prueba
          </CButton>
        </CForm>
      </div>
      <template #header>
        <h6 class="modal-title">Enviar Correo de Prueba</h6>
        <CButtonClose
          @click="closeModal('Close_Modal_Test_Boletin_Success')"
          class="text-white"
        />
      </template>
      <template #footer>
        <CButton
          @click="closeModal('Close_Modal_Test_Boletin_Success')"
          color="danger"
          >Cerrar</CButton
        >
        <CButton
          @click="closeModal('Close_Modal_Test_Boletin_Success')"
          color="success"
          style="display: none"
          >Accept</CButton
        >
      </template>
    </CModal>
    <div>
      <CToaster :autohide="5000" class="d-inline-block">
        <template v-for="toast in mostrarNotificacion">
          <CToast
            :key="'toast' + toast"
            :show="true"
            color="success"
            header="Notificacion Exitosa"
          >
            {{ mensaje_success }}
          </CToast>
        </template>
        <template v-for="toast1 in mostrarNotificacionAlerta">
          <CToast
            :key="'toast1' + toast1"
            :show="true"
            color="warning"
            header="Notificacion Alerta"
          >
            {{ mensaje_warning }}
          </CToast>
        </template>
        <template v-for="toast2 in mostrarNotificacionError">
          <CToast
            :key="'toast2' + toast2"
            :show="true"
            color="danger"
            header="Notificacion Alerta"
          >
            Error Comunicarse Con Soporte
          </CToast>
        </template>
      </CToaster>
    </div>
  </div>
</template>

<script>
export default {
  name: "CorreoPrueba",
  components: {},
  data() {
    return {
      email_prueba: "",
      boletin_id: 0,
      mostrarNotificacion: 0,
      mostrarNotificacionAlerta: 0,
      mostrarNotificacionError: 0,
      mensaje_warning: "Debe Completar Los Datos Vacio",
      mensaje_success: "Correo Enviado Correctamente.",
    };
  },
  props: {
    openModal: Boolean,
    boletin_prueba: Object,
  },
  computed: {},
  methods: {
    closeModal(type) {
      this.$emit("close-modal", type);
    },
    addMore() {},
    envioCorreoPrueba(evt) {
      let emailValida = this.validateEmail(this.email_prueba);
      if (emailValida == false) {
        this.mensaje_warning = "El correo no es valido";
        this.mostrarNotificacionAlerta += 1;
        return;
      }
      evt = evt ? evt : window.event;
      evt.target.disabled = true;
      this.$store.dispatch("auth/loading_http", "visible");
      this.$http
        .post(
          this.$httpUrlMail + "/mail/boletines/envio/prueba",
          {
            email_prueba: this.email_prueba,
            id: this.boletin_prueba.id,
          },
          { withCredentials: false }
        )
        .then((response) => {
          let data = response.data;
          if (data.status == "OK") {
            this.mensaje_success = data.message;
            evt.target.disabled = false;
            this.mostrarNotificacion++;
            this.email_prueba = "";
            this.closeModal("Close_Modal_Test_Boletin_Success");
          } else if (data.status == "error") {
            this.mensaje_warning = data.message;
            this.mostrarNotificacionAlerta += 1;
          } else {
            let mensajeError = "";
            let dataError = response.data;
            for (const key in dataError) {
              if (Object.hasOwnProperty.call(dataError, key)) {
                const element = dataError[key];
                for (const iterator of element) {
                  mensajeError += `${iterator} \n`;
                }
              }
            }
            this.mensaje_warning = mensajeError;
            this.mostrarNotificacionAlerta += 1;
            evt.target.disabled = false;
          }
        })
        .catch((error) => {
          this.mostrarNotificacionError++;
        })
        .finally(() => {
          this.$store.dispatch("auth/loading_http", "hidden");
          evt.target.disabled = false;
        });
    },
    validateEmail(email) {
      let mailformat = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
      if (email.match(mailformat)) {
        return true;
      }
      return false;
    },
  },
  mounted() {},
};
</script>

