import CorreoPrueba from '../modals/CorreoPrueba.vue'
import ProgramarCorreo from '../modals/ProgramarCorreo.vue'
import ModalCargaMensaje from "../modals/ModalCargaMensaje.vue";
export default {
    name: 'IndexBoletin',
    components: {
      CorreoPrueba,
      ProgramarCorreo,
      ModalCargaMensaje
    },
    data () {
      return {
        mensaje_success:'',
        mostrarNotificacion: 0,
        mostrarNotificacionAlerta: 0,
        mostrarNotificacionError:0,
        mensaje_warning:'Debe Completar Los Datos Vacio',
        items: [],
        fields: [
          { key: 'nombre',label:'Nombre',_classes: 'font-weight-bold'  },
          { key: 'cantidad',label:'Cantidad de registros'},
          { key: 'email_copia',label:'Con copia'},
          { key: 'contiene_archivo',label:'Contiene archivo'},
          { key: 'fecha_sistema',label:'Fecha creacion'},
          { key: 'accion',label:'Accion',
            _style: { width: '6%' },
            sorter: false,
            filter: false 
          }
        ],
        loading_tabla: false,
        boletin_delete: {},
        modal_eliminar_boletin: false,
        modal_correo_prueba_boletin:false,
        boletin_prueba:{
          id:'',
          nombre:'',
        },
        modal_correo_programar_boletin:false,
        boletin_programar:{
          id:'',
          nombre:'',
          cantidad:0,
        },
        parametros_configuracion:{
            maximo_boletines:10
        },
      }
       
    },
    methods: {
      goBack() {
        this.usersOpened ? this.$router.go(-1) : this.$router.push({path: '/boletines'})
      },
      getBoletines() {
            this.loading_tabla=true
            this.$store.dispatch('auth/loading_http','visible');
            this.$http.get(this.$httpUrlMail+'/mail/boletines',{ withCredentials:false}).then(response => {
              this.items = response.data;
              this.items.map((item,id) => {  
              if (id%2 == 0) {
                item['_classes'] = 'table-now'; 
              }
              return item;
              })
            }).catch((e)=>{
              this.mostrarNotificacionError ++;
            }).finally(() =>{this.$store.dispatch('auth/loading_http','hidden');this.loading_tabla=false});
      },
      pageChange (val) {
        this.$router.push({ query: { page: val }})
      },
      openModalDeleteBoletin(item){
        this.boletin_delete = item;
        this.modal_eliminar_boletin = true;
      },
      deleteBoletin() {
          this.modal_eliminar_boletin = false;
          this.$store.dispatch('auth/loading_http','visible');
            this.$http.delete(this.$httpUrlMail+'/mail/boletines/'+this.boletin_delete.id,{ withCredentials:false}).then(response => {
              if (response.data.status == "OK") {
                this.mostrarNotificacion ++;
                this.mensaje_success = "Boletin Eliminado Correctamente";
                this.items = this.items.filter(i => i.id !== this.boletin_delete.id); 
              }else{
                this.mostrarNotificacionAlerta ++;
                this.mensaje_warning = response.data.message;
              }
            }).catch((e)=>{
              this.mostrarNotificacionError ++;
            }).finally(() =>{this.$store.dispatch('auth/loading_http','hidden')});
      },
      openModalCorreoPruebaBoletin(item){
        this.modal_correo_prueba_boletin = true;
        this.boletin_prueba.id = item.id;
        this.boletin_prueba.nombre = item.nombre;
      },
      closeModal(key,message = ""){
        switch (key) {
          case 'Close_Modal_Test_Boletin_Success':
            this.modal_correo_prueba_boletin = false;
            break;
          case 'Close_Modal_Programing_Boletin_Success':
            this.modal_correo_programar_boletin = false;
            if (message != "") {
              let segundo = this.boletin_programar.cantidad >= 10000 ? 20 : 7;
              this.abrirmodalProgresoMensaje(segundo, true, message);
            }
            break;
        }
      },
      openModalCorreoProgramarBoletin(item){
        this.modal_correo_programar_boletin = true;
        this.boletin_programar.id = item.id;
        this.boletin_programar.nombre = item.nombre;
        this.boletin_programar.cantidad = item.cantidad;
      },
      abrirmodalProgresoMensaje(tiempo, abrir , message) {
        this.$root.$emit("abrirmodalProgresoMensaje", tiempo, abrir,message);
      },
      validateMaximoBoletin(){
        if(this.items.length >= this.parametros_configuracion.maximo_boletines){
          this.mensaje_warning = `Has alcanzado el límite de boletines creados. Para crear uno boletín, por favor elimina un boletín existente`;
          this.mostrarNotificacionAlerta++;
        }else{
          this.$router.push({path:'/mailing/boletines/create'})
        }
      },
      getConfigUser(){
        this.$store.dispatch("auth/loading_http", "visible");
        this.$http
          .get(this.$httpUrlMail+"/mail/config/user",{withCredentials:false})
          .then((response) => {
             this.parametros_configuracion = response.data;
          })
          .catch(() => {
            this.mostrarNotificacionError++;
          })
          .finally(() => this.$store.dispatch("auth/loading_http", "hidden"));
    },
    },
    mounted () {
        setTimeout(() => {
          this.getBoletines();
          this.getConfigUser();
        },300)
    },
  };