<template>
    <div>
        <CModal
            :show.sync="openModal"
            :no-close-on-backdrop="true"
            :centered="false"
            title="Modal title 2"
            size="lg"
            color="dark"
            >
            <div>
              <CForm @submit.prevent="envioCorreoProgramar($event)">
                    <p>Boletin: {{boletin_programar.nombre}}</p>
                    <hr>
                    <template v-for="(name, key) in radioNamesHorario">
                      <CRow form class="form-group" :key="name">
                        <CCol col="12">
                          {{ name }}
                        </CCol>
                        <CCol col="12">
                          <div role="group" class="custom-control custom-radio">
                            <input
                              id="uid-ip8fkjcnavi"
                              type="radio"
                              name="radio61"
                              @change="changeRadioHorario($event)"
                              class="custom-control-input"
                              value="inmediato"
                            />
                            <label for="uid-ip8fkjcnavi" class="custom-control-label">
                              Inmediato
                            </label>
                          </div>
                        </CCol>
                        <CCol col="12">
                          <CRow>
                            <CCol col="3">
                              <div role="group" class="custom-control custom-radio">
                                <input
                                  id="uid-ip8fkjcnasjl"
                                  type="radio"
                                  name="radio61"
                                  @change="changeRadioHorario($event)"
                                  class="custom-control-input"
                                  value="programado"
                                />
                                <label
                                  for="uid-ip8fkjcnasjl"
                                  class="custom-control-label"
                                >
                                  Programado
                                </label>
                              </div>
                            </CCol>
                            <CCol col="9">
                              <input
                                type="datetime-local"
                                name="date-radio61"
                                class="form-control"
                                id="uid-ip8fkjcnasgh"
                                :min="dateValue"
                                v-model="dateValue"
                                :disabled="fechaHorarioDisabled"
                              />
                            </CCol>
                          </CRow>
                        </CCol>
                      </CRow>
                    </template>
                    <CButton size="sm" color="danger" type="submit">
                        Enviar Correo
                    </CButton>
                    
                </CForm>
            </div>
            <template #header>
                <h6 class="modal-title">Enviar Correo</h6>
                <CButtonClose @click="closeModal('Close_Modal_Programing_Boletin_Success')" class="text-white"/>
            </template>
            <template #footer>
                <CButton @click="closeModal('Close_Modal_Programing_Boletin_Success')" color="danger">Cerrar</CButton>
                <CButton @click="closeModal('Close_Modal_Programing_Boletin_Success')" color="success" style="display:none;">Accept</CButton>
            </template>
        </CModal>
  <div>
          <CToaster :autohide="5000" class="d-inline-block">
             <template v-for="toast in mostrarNotificacion">
                <CToast
                  :key="'toast'+toast"
                  :show="true"
                  color="success"
                  header="Notificacion Exitosa"
                >
                  {{mensaje_success}}
                </CToast>
              </template>   
              <template v-for="toast1 in mostrarNotificacionAlerta">
                <CToast
                  :key="'toast1'+toast1"
                  :show="true"
                  color="warning"
                  header="Notificacion Alerta"
                >
                  {{mensaje_warning}}
                </CToast>
              </template>
              <template v-for="toast2 in mostrarNotificacionError">
                <CToast
                  :key="'toast2'+toast2"
                  :show="true"
                  color="danger"
                  header="Notificacion Alerta"
                >
                  Error Comunicarse Con Soporte
                </CToast>
              </template>  
          </CToaster>
  </div>
    </div> 
</template>

<script>
export default {
  name: 'ProgramarCorreo',
  components: {
  },
  data () {
    return {
        email_prueba:'',
        boletin_id:0,
        mostrarNotificacion: 0,
        mostrarNotificacionAlerta: 0,
        mostrarNotificacionError:0,
        mensaje_warning:'Debe Completar Los Datos Vacio',
        mensaje_success:'Correo Enviado Correctamente.',
        dateValue: new Date().toISOString().split(".")[0],
        fechaHorarioDisabled: false,
        radioNamesHorario: ["Selecciona el Horario de Envio"],
        tipo_hora_envio: '',
    }
  },
  props:{
    openModal: Boolean,
    boletin_programar: Object
  },
  computed:{
  },
  methods: {
    closeModal(type,mensaje = "") {
      this.$emit("close-modal",type,mensaje);
    },
    addMore() {
      
    },
    envioCorreoProgramar(evt){
      evt = (evt) ? evt : window.event;
      evt.target.disabled = true;
      if (this.tipo_hora_envio == "") {
        this.mensaje_warning = "Debe Seleccionar el Horario de Envio";
        this.mostrarNotificacionAlerta += 1;
        evt.target.disabled = false;
        return false;
      }
      this.$store.dispatch('auth/loading_http','visible');
      this.$http.post(this.$httpUrlMail+"/mail/boletines/envio",{
        dateValue:this.dateValue,
        tipo_hora_envio:this.tipo_hora_envio,
        id:this.boletin_programar.id
      },{ withCredentials: false }).then(response =>{
        let data = response.data;
        if (data.status == "OK") {
            //this.mensaje_success= data.message;
            evt.target.disabled = false;
            //this.mostrarNotificacion ++;
            this.email_prueba = '';
            this.closeModal('Close_Modal_Programing_Boletin_Success',data.message);
        }else if(data.status == "error"){
            this.mensaje_warning = data.message;
            this.mostrarNotificacionAlerta += 1;
        }else{
              let mensajeError = '';
              let dataError = response.data;
              for (const key in dataError) {
                if (Object.hasOwnProperty.call(dataError, key)) {
                  const element = dataError[key];
                  for (const iterator of element) {
                    mensajeError += `${iterator} \n`
                  }
                }
              }
              this.mensaje_warning = mensajeError
              this.mostrarNotificacionAlerta += 1;
              evt.target.disabled = false;
        }
      }).catch(error=>{this.mostrarNotificacionError ++;})
      .finally(() =>{this.$store.dispatch('auth/loading_http','hidden');evt.target.disabled = false;})
    },
    changeRadioHorario(event) {
      var optionText = event.target.value;
      if (optionText == "programado") {
        this.fechaHorarioDisabled = false;
        this.tipo_hora_envio = optionText;
        let fecha_programa = new Date();
        fecha_programa.setMinutes(fecha_programa.getMinutes() + 3);
        fecha_programa.setDate(fecha_programa.getDate());
        this.dateValue = this.toISOLocal(fecha_programa).split(".")[0];
      } else {
        this.fechaHorarioDisabled = true;
        this.tipo_hora_envio = optionText;
      }
    },
    toISOLocal(d) {
      var z = (n) => ("0" + n).slice(-2);
      var zz = (n) => ("00" + n).slice(-3);
      var off = d.getTimezoneOffset();
      var sign = off < 0 ? "+" : "-";
      off = Math.abs(off);

      return (
        d.getFullYear() +
        "-" +
        z(d.getMonth() + 1) +
        "-" +
        z(d.getDate()) +
        "T" +
        z(d.getHours()) +
        ":" +
        z(d.getMinutes()) +
        ":" +
        z(d.getSeconds()) +
        "." +
        zz(d.getMilliseconds()) +
        sign +
        z((off / 60) | 0) +
        ":" +
        z(off % 60)
      );
    },
  },
  mounted(){
      
  },
}
</script>

